/* customDatePicker.css */
.date-picker .react-datepicker {
    border-color: #d1d5db; /* Border color */
  }
  
  .date-picker .react-datepicker__header {
    background-color: #f9fafb; /* Header background */
  }
  
  .date-picker .react-datepicker__day-name, .date-picker .react-datepicker__day, .date-picker .react-datepicker__time-name {
    color: #374151; /* Font color */
  }
  
  .date-picker .react-datepicker__day--selected, .date-picker .react-datepicker__day--in-range, .date-picker .react-datepicker__day--in-selecting-range {
    background-color: #4f46e5; /* Selection background */
  }
  
  .date-picker .react-datepicker__day--keyboard-selected {
    background-color: #cbd5e1; /* Keyboard selection color */
  }  