/* src/components/worklog.css */

/* Container Styling */
.worklogs-container {
    width: 1400px;
    margin: 0 auto;
  }
  
  /* Date Range Picker Styling */
  .date-range-picker label {
    font-weight: 600;
    color: #2c3e50;
  }
  
  .date-range-picker .react-datepicker-wrapper {
    width: 100%;
  }
  
  /* Timeline Customization */
  .custom-timeline .rct-sidebar {
    background-color: #f7f9fc;
  }
  
  .custom-timeline .rct-header-root {
    background-color: #34495e;
    color: #ecf0f1;
  }
  
  .custom-timeline .rct-header-root .rct-dateHeader {
    background-color: #34495e;
    border: none;
  }
  
  .custom-timeline .rct-header-root .rct-dateHeader-primary {
    background-color: #2c3e50;
  }
  
  .custom-timeline .rct-items {
    background-color: #ffffff;
  }
  
  /* Timeline Item Styling */
  .rct-item.worklog-completed {
    background-color: #7CBB00 !important; /* 'green-grave' */
    border-color: #5ba500 !important;
  }
  
  .rct-item.worklog-in-progress {
    background-color: #E4AF21 !important; /* 'orange-grave' */
    border-color: #cc9c1e !important;
  }
  
  /* Custom Item Content */
  .custom-item-content {
    display: flex;
    align-items: center;
    padding: 5px;
  }
  
  .custom-item-content .worklog-info {
    font-size: 14px;
    color: #2c3e50;
  }
  
  /* Resize Handles */
  .custom-resize-handle {
    width: 10px;
    background-color: rgba(0, 0, 0, 0.1);
    cursor: ew-resize;
  }
  
  .custom-resize-handle.left-handle {
    left: -5px;
  }
  
  .custom-resize-handle.right-handle {
    right: -5px;
  }
  
  /* Modal Styling */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 50;
  }
  
  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    background: white;
    padding: 30px;
    border-radius: 8px;
    outline: none;
    max-width: 500px;
    width: 90%;
    z-index: 100;
  }
  
  .modal h2 {
    margin-top: 0;
  }
  
  .modal button {
    float: right;
  }
  
  /* Additional Styling */
  .custom-item.selected {
    border-color: #4A90E2 !important;
  }
  
  /* Full Width Timeline */
  .custom-timeline {
    width: 100%;
  }  