.changelog {
    font-family: 'Arial', sans-serif; /* Specify your font family */
    line-height: 1.6; /* Adjust line height for readability */
    padding: 20px; /* Add padding */
  }
  
  .changelog h1, .changelog h2, .changelog h3 {
    color: #333; /* Dark grey color for headers */
  }

  .changelog h1 {
    font-size: 2em; /* Larger font size for h1 */
  }

    .changelog h2 {
        font-size: 1.5em; /* Large font size for h2 */
    }

    .changelog h3 {
        font-size: 1.2em; /* Medium font size for h3 */
    }
  
  .changelog p {
    color: #666; /* Lighter text for paragraphs */
  }

    .changelog ul {
        list-style-type: disc; /* Use disc bullets for unordered lists */
    }

    .changelog ol {
        list-style-type: decimal; /* Use decimal numbers for ordered lists */
    }

    .changelog li {
        margin-bottom: 10px; /* Add margin below list items */
    }

    .changelog a {
        color: #4f46e5; /* Link color */
    }

    .changelog a:hover {
        text-decoration: underline; /* Underline links on hover */
    }

    .changelog blockquote {
        background-color: #f9fafb; /* Light grey background for blockquotes */
        border-left: 5px solid #4f46e5; /* Left border color */
        padding: 10px; /* Add padding inside blockquotes */
    }

    .changelog code {
        background-color: #f9fafb; /* Light grey background for code blocks */
        border: 1px solid #d1d5db; /* Border color for code blocks */
        padding: 2px 5px; /* Add padding inside code blocks */
    }

    .changelog pre {
        background-color: #f9fafb; /* Light grey background for preformatted text */
        border: 1px solid #d1d5db; /* Border color for preformatted text */
        padding: 10px; /* Add padding inside preformatted text */
        overflow: auto; /* Add scrollbars for long code blocks */
    }

    .changelog img {
        max-width: 100%; /* Make images responsive */
        height: auto; /* Prevent images from stretching */
    }

    .changelog table {
        border-collapse: collapse; /* Collapse table borders */
        width: 100%; /* Make tables fill their container */
    }

    .changelog th, .changelog td {
        border: 1px solid #d1d5db; /* Border color for table cells */
        padding: 8px; /* Add padding inside table cells */
        text-align: left; /* Align text to the left */
    }

    .changelog th {
        background-color: #f9fafb; /* Light grey background for table headers */
    }

    .changelog tr:nth-child(even) {
        background-color: #f9fafb; /* Alternate row background color */
    }

    .changelog tr:hover {
        background-color: #f0f4ff; /* Highlight row on hover */
    }

